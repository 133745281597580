import { createApp } from 'vue'
import 'vfonts/Lato.css'
import 'vfonts/FiraCode.css'
import router from "@/router";
import App from '@/pages/index/App.vue'
import './index.css'
//import 'windi.css'

const app = createApp(App);
app.use(router);
app.mount('#app');
