<template>

    <router-view name="topbar" :title="title"></router-view>
    <n-menu v-model:value="activeKey" mode="horizontal" :options="menuOptions" v-if="false" />
    <router-view />

</template>

<script>
    //import UvMap from './components/UvMap.vue'
    //import UvWorldMap from './components/UvWorldMap.vue'
    import { h } from 'vue'
    import { NIcon, NMenu } from 'naive-ui'
    import {
        Map as MapIcon,
        MapMarkedAlt as MapMarkedIcon,
    } from '@vicons/fa'

    function renderIcon(icon) {
        return () => h(NIcon, null, { default: () => h(icon) })
    }

    const menuOptions = [
        {
            label: 'Uv-varsel for Norge',
            key: 'UvNorway',
            icon: renderIcon(MapIcon),
        },
        {
            label: 'UV-varsel for hele verden',
            key: 'UvWorld',
            icon: renderIcon(MapMarkedIcon),
            disabled: true,
        },
    ]

    export default {
        name: 'App',
        components: {
            NMenu,
        },
        data() {
            return {
                activeKey: 'UvNorway',
                menuOptions: menuOptions,
                showdatepicker: true,
                smallMap: false,
                title: ''
            }
        },
        mounted() {
            let url = new URL(window.location.href);
            if (url.searchParams.get("smallmap")) {
                console.log(url.searchParams.get("smallmap"));
                this.smallMap = true;
                this.showdatepicker = false;
            }
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        background-color:#ededeb;
    }

    .topmenu {
        min-height: 64px;
        /*background-color: #003366;*/
        width: 100%;
    }

    body {
        font-family: Rubik,sans-serif;
        height: 100%;
        font-size: .875rem;
        --tw-text-opacity: 1;
        color: rgba(75,85,99,var(--tw-text-opacity));
        margin: 0;
        background-color: #ededeb;
    }
</style>
