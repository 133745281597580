////import Vue from "vue"
import { createRouter, createWebHistory } from 'vue-router'

//Vue.use(router);

const WorldMap = () => import(/* webpackChunkName: "WorldMap" */ './views/maps/World');
const NorwayMap = () => import(/* webpackChunkName: "NorwayMap" */ './views/maps/Norway');
const NorwayMapSmall = () => import(/* webpackChunkName: "NorwayMapSmall" */ './views/maps/NorwaySmall');
const Top = () => import(/* webpackChunkName: "Top" */ './components/Top');

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "home",
            components: {
                default: NorwayMap,
                topbar: Top
            }
        },
        {
            path: "/maps/norway",
            name: "norwaymap",
            components: {
                default: NorwayMap,
                topbar: Top
            }
        },
        {
            path: "/maps/norway/small",
            name: "norwaymapsmall",
            components: {
                default: NorwayMapSmall,
            }
        },
        {
            path: "/maps/norway/:x/:y/:z/:b/small",
            name: "norwaymapsmallgps",
            components: {
                default: NorwayMapSmall,
            },
            props: true
        },
        {
            path: "/maps/world",
            name: "worldmap",
            components: {
                default: WorldMap,
                topbar: Top
            }
        },
    ]
})
